import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60')
];

export const server_loads = [0,3,11,6];

export const dictionary = {
		"/.well-known/[...catchAll]": [15],
		"/api/[...catchAll]": [59],
		"/(auth)/auth": [13,[2]],
		"/(auth)/auth/signout": [14,[2]],
		"/callback/[...catchAll]": [60],
		"/[lang]/(default)": [~24,[3,11],[,12]],
		"/[lang]/(default)/about": [~26,[3,11],[,12]],
		"/[lang]/(default)/app": [~27,[3,11],[,12]],
		"/[lang]/(default)/become-a-partner": [~28,[3,11],[,12]],
		"/[lang]/(default)/become-a-partner/confirmation": [29,[3,11],[,12]],
		"/[lang]/(default)/careers": [~30,[3,11],[,12]],
		"/[lang]/(default)/careers/[id]/[title]": [~31,[3,11],[,12]],
		"/[lang]/(auth)/checkout": [~16,[3,4],[,,5]],
		"/[lang]/(default)/classes/[id]": [~32,[3,11],[,12]],
		"/[lang]/(default)/classes/[id]/[title]": [~33,[3,11],[,12]],
		"/[lang]/(auth)/confirmation": [17,[3,4,6]],
		"/[lang]/(auth)/confirmation/gift-card": [18,[3,4,6]],
		"/[lang]/(default)/credits": [~34,[3,11],[,12]],
		"/[lang]/(default)/discover/[city]": [~35,[3,11],[,12]],
		"/[lang]/(default)/discover/[city]/all/[category]": [~38,[3,11],[,12]],
		"/[lang]/(default)/discover/[city]/[vicinity]": [~36,[3,11],[,12]],
		"/[lang]/(default)/discover/[city]/[vicinity]/[category]": [~37,[3,11],[,12]],
		"/[lang]/(auth)/email-verification": [~19,[3,4],[,,7]],
		"/[lang]/(default)/faq": [~39,[3,11],[,12]],
		"/[lang]/(default)/follow-us": [~40,[3,11],[,12]],
		"/[lang]/(default)/for-business": [41,[3,11],[,12]],
		"/[lang]/(default)/free-trial": [~42,[3,11],[,12]],
		"/[lang]/(default)/friend/request/[token]": [~43,[3,11],[,12]],
		"/[lang]/(default)/friskvard": [44,[3,11],[,12]],
		"/[lang]/(default)/get-started": [~45,[3,11],[,12]],
		"/[lang]/(default)/gift-card": [~46,[3,11],[,12]],
		"/[lang]/(default)/gift-card/[id]": [~47,[3,11],[,12]],
		"/[lang]/(auth)/login": [~20,[3,4],[,,8]],
		"/[lang]/(default)/marketing": [~48,[3,11],[,12]],
		"/[lang]/(default)/membership": [~49,[3,11],[,12]],
		"/[lang]/(default)/occupational-health": [~50,[3,11],[,12]],
		"/[lang]/(default)/occupational-health/confirmation": [51,[3,11],[,12]],
		"/[lang]/(auth)/password/reset": [~21,[3,4],[,,9]],
		"/[lang]/(default)/privacy": [~52,[3,11],[,12]],
		"/[lang]/(auth)/signup": [~22,[3,4],[,,10]],
		"/[lang]/(default)/studio-faq": [~53,[3,11],[,12]],
		"/[lang]/(default)/studios": [~54,[3,11],[,12]],
		"/[lang]/(default)/studios/[cityOrStudioId]": [~55,[3,11],[,12]],
		"/[lang]/(default)/studios/[cityOrStudioId]/[name]": [~56,[3,11],[,12]],
		"/[lang]/(default)/terms": [~57,[3,11],[,12]],
		"/[lang]/(default)/trial": [~58,[3,11],[,12]],
		"/[lang]/(auth)/verify": [23,[3,4]],
		"/[lang]/(default)/[...catchAll]": [25,[3,11],[,12]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';